import * as React from "react";

import Page from "./../components/Layout/Page";
import Container from "../components/Layout/Container";

import { FacebookDarkIcon, InstagramDarkIcon } from "../images/svgs";
// markup
const ContactPage = () => {
  return (
    <Page className="contact">
      <section className="hero">
        <Container>
          <h1>
            <strong>Get In Touch</strong>
          </h1>
        </Container>
      </section>
      <section className="main-content">
        <Container>
          <div className="contact-details">
            <div className="phone">
              <h3>
                <strong>Phone</strong>
              </h3>
              <ul>
                <li>
                  <a href="tel:+919654956742">9654 956 742</a>
                </li>
                <li>
                  <a href="tel:+916909364955">6909 364 955</a>
                </li>
              </ul>
            </div>
            <div>
              <h3>
                <strong>Email</strong>
              </h3>
              <ul>
                <li>
                  <a href="mailto:kimi@whitewalls.in">kimi@whitewalls.in</a>
                </li>
              </ul>
            </div>
            <div>
              <h3>
                <strong>Visit Our Office</strong>
              </h3>
              <address>
                White Walls
                <br />
                Gr. Floor, Solomon's Cave,
                <br />
                Tuikual South,
                <br />
                Aizawl Mizoram
              </address>
              <div>
                (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.co.in/maps/place/White+Walls/@23.727464,92.7157443,17z/data=!4m5!3m4!1s0x0:0x4dfdde92fef8209!8m2!3d23.727464!4d92.717933"
                >
                  Get Directions
                </a>
                )
              </div>
            </div>
            <div className="social-links">
              <h3>
                <strong>Follow Us</strong>
              </h3>
              <div>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/WhiteWallsAizawl">
                      <FacebookDarkIcon />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/whitewallsaizawl/">
                      <InstagramDarkIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="map-wrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3652.5582580472774!2d92.7157443!3d23.727464!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4dfdde92fef8209!2sWhite%20Walls!5e0!3m2!1sen!2sin!4v1643963702952!5m2!1sen!2sin"
              width="800"
              height="600"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="googleMap"
            />
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default ContactPage;
